<template>
  <div class="container p-4">
    <form ref="form1" @submit.prevent>
      <p class="mb-4"><span class="text-danger font-weight-bold ml-2">※</span>は必須入力です。</p>
      <div class="form-group row">
        <label for="inputName" class="col-sm-3 col-form-label">氏名<span class="text-danger font-weight-bold ml-2">※</span></label>
        <div class="col-6 col-sm-3">
          <input v-model="formData.familyName" type="text" class="form-control" id="inputName" placeholder="氏" required />
        </div>
        <div class="col-6 col-sm-3">
          <input v-model="formData.firstName" type="text" class="form-control" placeholder="名" required />
        </div>
      </div>
      <div class="form-group row">
        <label for="inputAge" class="col-sm-3 col-form-label">年齢<span class="text-danger font-weight-bold ml-2">※</span></label>
        <div class="col-sm-6">
          <div class="col-sm-6 p-0 pr-sm-3">
            <input v-model="formData.age" type="number" class="form-control" id="inputAge" placeholder="年齢" min="30" required />
          </div>
          <small class="form-text text-muted text-left">30歳以上の方が対象です。半角数字で入力してください。</small>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputSex" class="col-sm-3 col-form-label">性別<span class="text-danger font-weight-bold ml-2">※</span></label>
        <div class="col-sm-3">
          <select v-model="formData.sex" class="form-control" id="inputSex" required>
            <option selected></option>
            <option :value="CONST.MEMBER.SEX.MALE">男性</option>
            <option :value="CONST.MEMBER.SEX.FEMALE">女性</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputZipCode" class="col-sm-3 col-form-label">郵便番号<span class="text-danger font-weight-bold ml-2">※</span></label>
        <div class="col-sm-6">
          <div class="input-group">
            <input v-model="formData.zipCode" type="text" class="form-control" id="inputZipCode" placeholder="例) 1540001" pattern="[0-9]{7}" required />
            <div class="input-group-append">
              <button type="button" class="btn btn-secondary p-0" style="font-size: 0.7em" id="searchZipcode" @click="zipcodeToAddress">郵便番号から<br>住所入力</button>
            </div>
          </div>
          <small class="form-text text-muted text-left">ハイフンを除いた7桁の半角数字で入力してください。</small>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">住所<span class="text-danger font-weight-bold ml-2">※</span></label>
        <div class="col-sm-6">
          <select v-model="formData.address1" id="inputAddress1" class="form-control" required>
            <option value="" disabled selected style="display: none">都道府県</option>
            <option v-for="(pref, id) in prefs" :key="pref" :value="id">{{ pref }}</option>
          </select>
          <input v-model="formData.address2" type="text" class="form-control" id="inputAddress2" placeholder="市区町村" required />
          <input v-model="formData.address3" type="text" class="form-control" id="inputAddress3" placeholder="番地" required />
          <input v-model="formData.address4" type="text" class="form-control" id="inputAddress4" placeholder="建物名・建物番号" />
          <small class="form-text text-muted text-left">検査キットを送付する住所となります。正確に記載してください。</small>
          <input v-model="formData.address" type="hidden" class="form-control" id="inputAddress" />
        </div>
      </div>
      <div class="form-group row">
        <label for="inputEmail" class="col-sm-3 col-form-label">メールアドレス<span class="text-danger font-weight-bold ml-2">※</span></label>
        <div class="col-sm-6">
          <input v-model="formData.email" type="email" class="form-control" id="inputEmail" placeholder="メールアドレス" required />
        </div>
      </div>
      <div class="p-3" />
      <ul class="nav mt-3">
        <li><button type="button" class="btn_next btn btn-primary" @click="onClickConfirmButton">確認する</button></li>
      </ul>
      <ul class="nav mt-3">
        <li><button type="button" class="btn_next btn btn-secondary" @click="back">戻る</button></li>
      </ul>
    </form>
  </div>
</template>
<script>
import { Mixin } from '@/mixin.js'
import { checkStatus } from '@/utils/common.js'
import { CONST } from '@/constants.js'
import { getAddressByZipcode } from '@/api/survey.js'

export default {
  mixins: [Mixin],
  data() {
    const formDataJson = localStorage.getItem('registerFormDataJson')

    return {
      search: false,
      CONST: CONST,
      formData:
        formDataJson == null
          ? {
              familyName: '',
              firstName: '',
              age: '',
              sex: '',
              zipCode: '',
              address: '',
              email: '',
            }
          : JSON.parse(formDataJson),
      prefs: {
        1: '北海道',
        2: '青森県',
        3: '岩手県',
        4: '宮城県',
        5: '秋田県',
        6: '山形県',
        7: '福島県',
        8: '茨城県',
        9: '栃木県',
        10: '群馬県',
        11: '埼玉県',
        12: '千葉県',
        13: '東京都',
        14: '神奈川県',
        15: '新潟県',
        16: '富山県',
        17: '石川県',
        18: '福井県',
        19: '山梨県',
        20: '長野県',
        21: '岐阜県',
        22: '静岡県',
        23: '愛知県',
        24: '三重県',
        25: '滋賀県',
        26: '京都府',
        27: '大阪府',
        28: '兵庫県',
        29: '奈良県',
        30: '和歌山県',
        31: '鳥取県',
        32: '島根県',
        33: '岡山県',
        34: '広島県',
        35: '山口県',
        36: '徳島県',
        37: '香川県',
        38: '愛媛県',
        39: '高知県',
        40: '福岡県',
        41: '佐賀県',
        42: '長崎県',
        43: '熊本県',
        44: '大分県',
        45: '宮崎県',
        46: '鹿児島県',
        47: '沖縄県',
      },
    }
  },
  created() {
    // ステータスチェック
    checkStatus(this, 5)
  },
  mounted() {
  },
  methods: {
    onClickConfirmButton() {
      this.formData.address = ([
        this.prefs[document.querySelector('#inputAddress1').value],
        document.querySelector('#inputAddress2').value,
        document.querySelector('#inputAddress3').value,
        document.querySelector('#inputAddress4').value,
      ]).join(" ")

      if (!this.$refs.form1.reportValidity()) {
        alert('必須項目が未入力、または入力に誤りがあります。確認してください。')
        return
      }

      // localStorageにフォーム入力データを保存
      localStorage.setItem('registerFormDataJson', JSON.stringify(this.formData))

      // 登録確認画面へ
      this.$router.push({name: 'RegisterConfirm'})
    },
    async zipcodeToAddress() {
      if (this.search) return
      const button = document.querySelector('#searchZipcode')
      const zipcode = document.querySelector('#inputZipCode').value
      if (!zipcode.match(/^[0-9]{7}$/)) {
        alert('郵便番号は7桁の半角数字で入力してください。')
        return
      }
      this.search = true
      button.innerHTML = '住所検索中…'
      const common = (a, b) => {
        return (a.length == 0 || b.startsWith(a)) ? a : common(a.slice(0, -1), b);
      }
      const addresses = await getAddressByZipcode(zipcode)
      if (!addresses) {
        alert('この郵便番号に該当する住所がありません。')
      } else {
        const address1 = document.querySelector('#inputAddress1')
        const address2 = document.querySelector('#inputAddress2')
        this.formData.address1 = address1.value = addresses.map(a => a.prefcode).reduce(common)
        this.formData.address2 = address2.value = addresses.map(a => a.address2 + a.address3).reduce(common)
      }
      this.search = false
      button.innerHTML = '郵便番号から<br>住所入力'
    },
  },
}
</script>
<style src="../assets/css/pages/questionnaire.css" scoped></style>
