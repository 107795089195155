import { sendMultipleCipher, sendMultipleCipherFromServer } from '@/api/common.js'
import request from '@/utils/request'
import { config } from '@/constants.js'
import axios from 'axios'

export function getSurveySecretKey(data) {
  return sendMultipleCipherFromServer(
    '/api/survey/getSurveySecretKey',
    data,
  )
}

export function getSurveyDetail(data) {
  return request({
    url: '/api/survey/getSurveyDetail',
    method: 'post',
    data
  })
}

export function getSurveyQuestions(data) {
  return request({
    url: '/api/survey/getSurveyQuestions',
    method: 'post',
    data
  })
}

export function sendAnswer(data) {
  return sendMultipleCipher(
    '/api/survey/sendAnswer',
    data,
  )
}

export function getUserAnswer(data) {
  return sendMultipleCipher(
    '/api/survey/getUserAnswer',
    data
  )
}

export function getResult(id) {
  return request({
    url: `/api/survey/result/${id}`,
    method: 'get',
  });
}

export function getMyAnswer(data) {
  return sendMultipleCipher(
    `/api/survey/decryptMyAnswer`,
    data,
  )
}

export function getAdvanceSurvey() {
  return request({
    url: `/api/survey/getAdvanceSurvey`,
    method: 'get',
  });
}

export async function getAddressByZipcode(zipcode) {
 const res = await axios.get(config.ZIPCODE_API_URL + zipcode)
  if (!res || !res.data || !res.data.results) {
    return false
  }
  return res.data.results
}

export function sendSamplingDate(data) {
  return request({
    url: '/api/survey/sendSamplingDate',
    method: 'post',
    data,
  })
}

export function sendResearchDays(data) {
  return request({
    url: '/api/survey/sendResearchDays',
    method: 'post',
    data,
  })
}

export function decline(data) {
  return request({
    url: '/api/survey/decline',
    method: 'post',
    data,
  })
}

export function updateActivateFlg(data) {
  return request({
    url: '/api/survey/updateActivateFlg',
    method: 'post',
    data,
  })
}
